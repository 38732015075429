@media print {

    .logo-print {
        width: 150px;
        height: auto;
    }

    .order-details-wrap {

        margin-top: -30px;
        
        .order-item-details {
            margin-left: -25px;
        }

        .co-collapse-box {
            margin-top: -30px;
            .justify-content-between {
                
                width: 60% !important;
            }

            .lef-chevron-up {
                display: none;
            }

            .order-item-image img {
                width: 40px;
                height: auto;
            }

            .order-item-wrapper  {
                margin-top: 0px;
                margin-bottom: 40px;
                .col-md-8 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        } 

        .items-warehouse-print {
            margin-bottom: 50px;
            
        }
    }
    
    .order-details {
        .row {
            flex-wrap: nowrap;
            flex-direction: column;
        }
        .order-details-content:first-of-type {    
            flex: 0 0 100%;
            max-width: 100%;
        }

        .order-details-content {    
            
            flex: 0 0 100%;
            max-width: 100%;
        }

        .order-details-content:last-of-type {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

   

    .cart-print-ftr {

        margin-top: -100px;

        .disclaimerContent {
            text-align: center; 
            margin-top: -10px;
        }
       

        .horizontalMenuLinks, .CLAwardDisclaimer  {
            display: none;
        }

        section {
            div:nth-child(2) {
                .col-md-7, .col-md-5 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
              }
        }

        
        
    }

    body.print-section-only {
      * {
        visibility: hidden;
      }
      header,
      main,
      footer {
        display: none;
      }
    }
  
    #printSection,
    #printSection * {
      visibility: visible;
    }
  
    #printSection {
      position: fixed;
      left: 0;
      top: 0;
    }
  }