// Breakpoints
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

// Colors
$base-theme: Default;
$skin-name: ShoppingCart;
$swatch-name: Default;
$border-radius: 2px;
$accent: #4a7dc4;
$accent-secondary: #bfdaff;
$secondary: #f1f7ff;
$info: #0d95a5;
$success: #61a40a;
$warning: #ffc000;
$error: #d12d2d;
$complete: #d3d3d3;
$white: #ffffff;

$copy: #444;
$cyan: #009dd5;
$green: #61a40a;
$grey: #666;
$darkgrey: #1e1e1e;
$orange: #fa6100;
$periwinkle: #6a86b8;
$red: #a41f35;
$blue: #0077b5;
$lightblue: #def3f5;

$medium-grey: #999;

$light-blue: #def3f6;
$light-green: #c4d600;
$light-grey: #ccc;
$lighter-grey: #dee2e6;
$light-orange: #ffa400;

$separator: $light-grey;

// Bootstrap overrides
$primary: #0e2d52; // Navy
$secondary: #6a86b8;
$success: $green;
$warning: $orange;
$info: #0d95a5; // Teal
$light: #f6f6f6;

// LE variables
$link-color: $cyan;
$link-hover-decoration: none;
$inputborders: #ced4da;

$font-zilla: "Zilla Slab", Times, serif;
$font-roboto: "Roboto", Arial, sans-serif;

// Kendo Overrides
$border-radius: 0;
