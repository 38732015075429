@import "./styles/utilities.scss";
@import "./styles/mix-ins.scss";
@import "./styles/print.scss";

.main-nav .nav-item.active {
  font-weight: 700;
}

.footer-seals {
  max-width: 100%;
  overflow: hidden;
}

/* ADA Skip links */
a.ada-link-wrap {
  font-weight: normal !important;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
  opacity: 0;

  &:focus {
    opacity: 1;
    z-index: 10001;

    span {
      text-decoration: none !important;
    }
  }
}

/* ADA Skip Links End */

.main-nav.navbar-collapse {
  position: absolute;
}

.nav-account-menu {

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff !important;
  }
}

/* Lef backdrops override */
lef-shopping-cart .menu-backdrop.show {
  z-index: 580 !important;
}

/* Side Nav Account Menu */
.sidenav .nav-account-menu .account-menu-item>a {
  color: #fff !important;
}

.sidenav {
  overflow: hidden;

  .sidenav-header {
    height: 65px;
  }

  .side-nav-content-wrap {
    height: calc(100% - 65px);
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .menu-user-logged-in {
    .nav-account-menu {
      .dropdown-menu {
        background-color: $primary !important;
        width: 350px;
        left: -20px !important;

        p {
          color: #fff;
          font-size: 16px;
          border-top: 1px solid #fff;
          padding-top: 20px;
        }

        a {
          color: #fff !important;
          font-size: 16px;

          &:hover {
            color: $info !important;
            background-color: transparent;
          }
        }
      }
    }
  }

  .dropdown-toggle {
    &.nav-account-menu-link {
      font-size: 18px;
      position: relative;
      padding-right: 20px;
      margin-left: 0;
      height: auto;

      &:focus {
        text-decoration: none;

        span {
          text-decoration: none;
        }
      }

      .nav-account-menu-icon-wrap {
        .sidenav-icon-pos {
          color: #fff !important;
          position: absolute;
          right: 0;
          left: auto;
          top: 2px;
        }
      }

      &:hover {
        .sidenav-icon-pos {
          color: $info !important;
        }
      }
    }
  }
}

.header-sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 599;
}

// Global
html,
body {
  font-size: 18.66px;
  color: $copy;
  height: 100%;
}

main {
  min-height: calc(100vh - 175px);
}

b,
strong {
  font-weight: bold;
}

a {
  &:hover {
    cursor: pointer;

    span {
      text-decoration: underline;
    }

    i {
      color: $primary !important;
    }
  }

  &.text-info {
    color: $info !important;
    font-weight: 700;

    &:hover {
      color: $primary !important;
    }
  }

  &.text-primary {
    color: $primary;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  &.bullets {
    li {
      position: relative;
    }

    li::before {
      content: "•";
      font-weight: bold;
      position: absolute;
      left: -10px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.border-separator {
  border-color: $separator !important;
}

.section-title-rule {
  border-bottom: 1px solid $info;
  line-height: 0.1em;
  margin-bottom: 40px;

  span {
    background-color: #fff;
    padding: 0 10px;
  }
}

.alert {
  display: flex;
  align-items: baseline;
  background-color: $light !important;
  margin-bottom: .5rem;

  i {
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
  }

  .k-i-check-circle {
    font-size: 18px;
    position: relative;
    top: -2px;
  }
}

.alert-info {
  border-color: $cyan;
}

.alert-warning,
.alert-danger {
  border-color: $red;
}

.alert-success {
  border-color: $success;
}

.text-underline {
  text-decoration: underline;
}

.premier {
  color: $primary;
  font-weight: 700;
}

.cart-autoship-txt {
  color: $primary;
  font-family: $font-zilla;
  font-weight: 700;
}

.cart-box {
  background-color: $light;
  padding: 20px;

  .cart-box-title {
    color: $primary;
    font-weight: 500;
  }

  .cart-box-header {
    color: #fff;
    background-color: $primary;
    padding: 10px 20px;
  }
}

.float-cart-action {
  background-color: #fff;
  margin: 0 -15px;
  padding: 10px 15px;
  z-index: 99;
}

.absolute-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 599;
}

.k-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  opacity: 1 !important;
}

.k-input,
.k-input-solid,
.k-input-inner {
  &:focus {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: transparent !important;
    outline: -webkit-focus-ring-color auto 1px !important
  }

  &:focus-within {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: transparent !important;
    outline: -webkit-focus-ring-color auto 1px !important
  }
}

// Buttons
button {
  &:focus {
    outline: solid !important;
  }
}

.btn-circle {
  line-height: 1.45;
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
}

.btn-warning {
  font-weight: bold;
}

.btn-primary-outline {
  border: 1px solid $primary;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}

.btn-link {
  color: $info;
  font-weight: 700;
  padding: 0;
  background-color: transparent;
  border: none;

  &:hover {
    color: $primary;
  }
}

.btn-link-txt {
  color: $copy;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;

  i {
    position: relative;
    top: 2px;
  }

  &:hover {
    span {
      text-decoration: underline !important;
    }

    i {
      color: $primary !important;
    }
  }
}

.btn-white {
  color: $primary;
  background-color: #fff;
  border: 1px solid $primary;
  transition: all ease-in 0.2s;

  &:hover {
    color: #fff;
    background-color: $primary;

    .premier {
      color: #fff;
    }
  }
}

.btn-white-orange {
  color: $orange;
  background-color: #fff;
  border: 1px solid $orange;
  transition: all ease-in 0.2s;

  &:hover {
    color: #fff;
    background-color: $orange;
  }

  &:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: transparent;
    background-color: #d45200;
    outline: -webkit-focus-ring-color auto 1px !important;

    /* Firefox */
    @-moz-document url-prefix() {
      border: 2px solid $primary !important;
    }

    /* Safari, Chrome, and Opera */
    @media not all and (min-resolution:.001dpcm) {
      @supports (-webkit-appearance:none) {
        border: 2px solid $primary !important;
      }
    }
  }
}

.btn-white-info {
  color: $info;
  background-color: #fff;
  border: 1px solid $info;
  transition: all ease-in 0.2s;

  &:hover {
    color: #fff;
    background-color: $info;
  }
}

.btn-continue-shp {
  color: $primary;
  font-weight: 700;
  display: flex;
  align-items: center;

  i {
    color: $orange;
    margin-right: 5px;
    margin-top: -1px;
  }

  &:hover {
    color: $info;
  }
}

.not-ready {
  display: block;
  font-size: 76px;
  margin: auto auto;
  padding: 150px;
  background: rgba(0, 0, 0, 0.15);
}

// Form controls

.form-control {
  iframe {
    input {
      &:focus {
        box-shadow: none !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border-color: transparent !important;
        outline: -webkit-focus-ring-color auto 1px !important;
      }
    }
  }

}


.invalid-feedback {
  color: $red;
  font-size: 14px;
}

select {
  color: $copy;
  font-size: 16px;
  padding: 0.375rem 1.5rem 0.375rem 0.675rem;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid $inputborders;
  border-radius: 0;
  // outline: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: url("/assets/images/chevron-down.png") top 60% right 6px / 1rem no-repeat $white !important;

  &.form-control {
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  &::-ms-expand {
    display: none;
  }

  // &:focus {
  //   color: #000;
  //   border-color: $blue;
  //   box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.75);
  // }

  &.form-control.is-invalid {
    background-image: url("/assets/images/chevron-down-error.png") !important;
  }

  &.form-control.is-invalid,
  &.form-control.ng-untouched.ng-invalid {
    color: $medium-grey;
  }
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;

  +label {
    margin-bottom: 0;
  }
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;

  &:checked {
    color: $light;
    background-color: $primary;
    border-color: $grey;
  }

  &:focus,
  &:focus-within {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: transparent !important;
    outline: -webkit-focus-ring-color auto 1px !important
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

// Coveo Add Item Search
.le-search-item-img {
  img {
    width: 80px;
    height: auto;
  }
}

.CoveoSearchInterface .coveo-results-column {
  padding: 0;
}

.CoveoSearchInterface .coveo-search-section {
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.coveo-main-section {
  font-family: $font-roboto !important;
}

.coveo-title {
  font-weight: bold;
}

.magic-box-input {
  width: 100%;
}

.star-ratings-css {
  color: #fff;
  text-align: left;
  position: relative;
  overflow: hidden;

  a {
    color: #fff;
  }

  i {
    font-size: 18px;
    line-height: 1.5em;
    padding: 0 3px 0 0;
  }
}

.star-ratings-css-top {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 24px;

  i {
    color: $green;
  }
}

.star-ratings-css-bottom {
  padding: 0;
  display: block;
  z-index: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;

  i {
    color: #c5c5c5;
  }
}

// Kendo Forms
.k-floating-label-container.k-state-empty>.k-label,
.k-floating-label-container.k-empty>.k-label {
  left: calc(0.15rem + 1px);
  padding-top: 5px;
}

.k-form textarea.k-textarea {
  resize: vertical;
}

.form-group {

  .k-textbox,
  .k-dropdown {
    width: 100% !important;
  }
}

.inline-form-field {
  .form-group {
    margin-bottom: 0 !important;
  }
}

.inline-form-btn {
  width: 100%;
  margin-bottom: 10px;
  height: 38px;
}

.k-form .k-form-field {
  margin-top: 0.25rem;
}

.k-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 0 !important;
}

.k-checkbox::before {
  background-color: $accent;
}

.k-checkbox:checked::before {
  border-radius: 0 !important;
}

.k-checkbox:focus {
  box-shadow: none;
}

.k-checkbox:checked {
  color: #1a1a1a;
  border-color: #333;
  background-color: $light;
}

.wra-step-wrap {
  display: flex;
  align-items: baseline;
}

.wra-step-number,
.wra-step-title {
  color: $primary;
  font-size: 1.333rem;
  font-weight: bold;
  padding: 0 0.5rem 0 0;
}

// Kendo Grids
.k-grid {
  .k-grid-header-wrap {
    background-color: $primary;
    color: $white;
    font-weight: 700;

    th {
      border-style: none;
    }
  }

  .k-grid-content {
    td {
      border-style: none;
    }
  }
}

// Kendo Tabs Overrides
.k-input {
  font-size: 16px;
}

.k-tabstrip-items {
  border-color: $info;
}

.k-tabstrip-items .k-item {
  color: $info;
  font-weight: 700;
  border-color: $info;
  border-bottom-color: transparent;

  &:focus {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.25);
  }
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item {
  border-radius: 0 !important;
}

.k-tabstrip-items-wrapper {
  border-color: $info;
}

.k-tabstrip-items .k-item.k-state-active,
.k-tabstrip-items .k-item.k-state-selected {
  color: $info;
  border-color: $info;
}

.k-tabstrip-items {
  .k-item {
    &:not(.k-active) {
      &:hover {
        color: #fff;
        background-color: $info;
        border-color: $info;
      }
    }
  }

  &.k-state-hover {
    color: #fff;
    background-color: darken($info, 15%);
    border-color: $info;
  }
}

.k-tabstrip-items-wrapper .k-item.k-active {
  color: #fff;
  border-color: $info;
  border-bottom-color: $info !important;
  background-color: $info;
}

.k-tabstrip>.k-content {
  border-color: transparent;
  padding: 0.5rem;
}

// Kendo Pager Overrides
.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  border-color: $info;
  color: $info;
}

.k-pager-numbers .k-link,
.k-pager-refresh,
.k-pager-nav.k-link {
  border-color: $info;
  color: $info;
  background-color: #fff;
  margin-right: 10px;
}

.k-pager-numbers .k-link.k-state-selected {
  border-color: $info;
  color: #fff;
  background-color: $info;
}

// Kendo Overrides
.k-dialog-close {
  &:hover {
    text-decoration: none;
  }
}

.k-window-action {
  opacity: 0.75;
}

.k-dialog {
  .k-window-titlebar {
    padding: 0.75rem 1rem;

    .k-icon.k-i-x {
      color: $info;
      font-size: 1.333rem;
    }
  }

  &.k-widget {
    border: none;
  }
}

.k-button-md.k-icon-button .k-button-icon.k-svg-icon>svg {
  height: 26px;
  color: $info;
}

.k-window-title {
  color: $primary;
  font-family: $font-zilla;
  font-size: 1.333rem;
  font-weight: 600;
  justify-content: center;
}

.k-radio {
  width: 18px;
  height: 18px;
  border-color: $primary;
}

.k-checkbox:disabled,
.k-checkbox.k-disabled {
  opacity: 0.5;
}

.k-radio:checked {
  color: $info;
  border-color: $primary;
  background-color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: $info;
    border-radius: 6px;
  }
}

.k-radio::before {
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
}

.k-radio:focus {
  border: transparent !important;
  box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 1px !important;

}

.k-radio:disabled,
.k-radio:disabled+.k-radio-label {
  opacity: 0.5;
}

.k-widget {
  border-color: $inputborders;
}

.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap {
  border-color: $inputborders;
}

.k-select .k-icon {
  color: $info;
}

.k-numerictextbox .k-numeric-wrap .k-select {
  background-color: $light;
  border-color: transparent;
}

.k-dropdown {
  .k-icon {
    font-family: "lef" !important;
  }

  .k-i-arrow-s {
    &::before {
      content: "\f078";
    }
  }
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  color: $copy;
  background-color: #fff;
  border-color: $inputborders;
}

.k-dropdown .k-dropdown-wrap:hover,
.k-dropdowntree .k-dropdown-wrap:hover,
.k-dropdown .k-dropdown-wrap.k-state-hover,
.k-dropdowntree .k-dropdown-wrap.k-state-hover {
  color: $copy;
  background-color: $light;
}

.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select {
  color: $info;
  background-color: $light;
  border-color: transparent;
}

button.k-button-clear:focus {
  border: 1px solid $copy !important;
}

.k-button {
  &.k {
    &-info {
      border-color: $info;
      background-color: $info;
    }

    &-action {
      color: #fff;
      border-color: $orange;
      background-color: $orange;
    }

    &-warning {
      border-color: $error;
      background-color: #ff0000 !important;
    }

    &-success {
      border-color: $success;
      background-color: $success;
    }

    &-secondary {
      border-color: $accent-secondary;
      background-color: $accent-secondary;
    }
  }
}

.k-button.k-flat {
  &.k {
    &-info {
      color: $info;
    }

    &-warning {
      color: $error;
    }

    &-success {
      color: $success;
    }

    &-secondary {
      color: $accent-secondary;
    }

    &-link {
      color: $link-color;
    }
  }
}

// Order Summary
.focus-box {
  position: relative;
  padding: 20px;

  kendo-popup {
    background-color: #fff;
    z-index: 10001 !important;
    width: calc(100% + 40px);
    top: 0;
    margin-left: -20px;
  }

  .k-popup {
    padding: 15px;
  }

  .btn-link {
    font-weight: 700;
  }

  .focus-element {
    position: absolute;
    background: $white;
    padding: 1rem;
    z-index: 10001;
    top: 0;
    left: -20px;
    right: -20px;
  }
}

.k-grid a {
  color: $info !important;
}

.k-grid a:hover {
  color: $primary;
  text-decoration: none;
}


.mainFlyout>.k-child-animation-container,
.mainFlyout>.k-child-animation-container>.k-popup {
  height: 100% !important;
}

@media screen {
  #printSection {
    display: none;
  }
}

@media screen and (max-width: 414px) {
  .le-header {
    lef-header-floating-cart {
      .floating-cart {
        left: inherit !important;
        width: 100vw !important;
        margin: 0 -15px 0 0 !important;
      }
    }
  }

  .k-window-title {
    white-space: break-spaces;
    line-height: 1.15em;
  }
}

@media screen and (max-width: 480px) {

  .CoveoSearchInterface .CoveoSearchbox {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .inline-form-field {
    width: 65%;
  }

  .inline-form-btn {
    width: auto;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  body.le-mobile-nav-set {
    .le-header {
      position: relative !important;
      background-color: transparent !important;
      top: 0 !important;
    }

    .main-navbar {
      transition: all ease-in .25s;
      display: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .le-header.le-header-sm {
    min-height: 115px;
  }
}