@import "./variables.scss";

.text-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-around {
  justify-content: space-around;
}

::placeholder {
  font-size: 16px;
}

// Links
.link-underline {
  &:hover {
    >span {
      text-decoration: underline;
    }

    i {
      color: $primary !important;
      text-decoration: none;
    }
  }
}

// Font Sizes
.font-md {
  font-size: 16px !important;
}

// Background Color
.bg-lightblue {
  background-color: $lightblue !important;
}

.bg-light-grey {
  background-color: $complete;
}

.text-blue {
  color: $blue !important;
}

.text-medium-grey {
  color: $medium-grey !important;
}

// Position
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

// Dimensions
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

// Transforms
.mirror-90 {
  transform: scaleX(-1) rotate(90deg);
}

.mirror-270 {
  transform: scaleX(-1) rotate(270deg);
}

// Border
.border-top-btm-grey {
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
}

.border-none {
  border: none !important;
}

.border-btm-none {
  border-bottom: none !important;
}

.border-right {
  border-right: 1px solid $separator;
}

.border-bottom-dashed {
  border-bottom: 1px dashed $darkgrey;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

// DO NOT USE (but keep it because it's fun!)
.👻 {
  display: none;
}

@media screen and (min-width: 576px) {
  .border-sm-right {
    border-right: 1px solid $separator;
  }
}

@media screen and (min-width: 768px) {
  .border-md-right {
    border-right: 1px solid $separator;
  }

  .w-md-u {
    width: unset !important;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }
}

@media screen and (min-width: 992px) {
  .mx-lg-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .ml-lg-0 {
    margin-left: 0;
  }

  .ml-lg-1 {
    margin-left: 0.25rem;
  }
}